import Parallax, {handleScrolling} from './libs/parallax'
import "lite-youtube-embed";
import "lite-youtube-embed/src/lite-yt-embed.css";

if (document.querySelector('[data-parallax]')) {
  window.addEventListener('scroll', handleScrolling);
}



// smooth scroll
const scrollFunc = element => {
  element.addEventListener('click', e => {
    e.preventDefault();
    document.querySelector(element.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
};
// Example Use
// const navLinks = document.querySelectorAll('.resources-nav a');
// navLinks.forEach(scrollFunc);


// lazy loading images
const images = document.querySelectorAll('[data-src]');
const imgOptions = {};
const imageOberserver = new IntersectionObserver((entries, imageOberserver) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return
      preloadImage(entry.target);
      imageOberserver.unobserve(entry.target);
  });
}, imgOptions);

const preloadImage = img => {
  const src = img.getAttribute('data-src');
  img.parentElement.parentElement.classList.add('fade-in')
  if (!src) return;
  img.src = src;
}

images.forEach(img => {
  imageOberserver.observe(img);
})
